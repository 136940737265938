<template>
  <div>
    <b-card>
      <div class="section-title">
        <span class="font-weight-bolder">{{ $t('DepositTaobao') }}</span>
        <div class="income-order-search order-search">
          <div class="anan-input search-input">
            <div class="anan-input__inner anan-input__inner--normal">
              <input
                v-model="search"
                type="text"
                :placeholder="$t('search')"
                class="anan-input__input"
              >
              <div class="anan-input__suffix">
                <i class="anan-input__clear-btn anan-icon" />
                <i class="anan-input__suffix-icon anan-icon">
                  <i class="fal fa-search" />
                </i>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="nav-panel anan-tabs anan-tabs-line anan-tabs-normal anan-tabs-top">
        <div class="anan-tabs__nav">
          <div class="anan-tabs__nav-warp">
            <div
              class="anan-tabs__nav-tabs"
              style="transform: translateX(0px)"
            >
              <div
                class="anan-tabs__nav-tab"
                :class="{ active: isActive === 'all' }"
                style="white-space: normal"
                @click="getData('all')"
              >
                <span> {{ $t('DepositTaobao') }} </span>
              </div>

              <div
                class="anan-tabs__nav-tab"
                :class="{ active: isActive === 'cancel' }"
                style="white-space: normal"
                @click="getData('cancel')"
              >
                <span> {{ $t('Refunds') }} </span>
              </div>

              <div
                class="anan-tabs__nav-tab"
                :class="{ active: isActive === 'panding' }"
                style="white-space: normal"
                @click="getData('panding')"
              >
                <span> {{ $t('key-122') }} </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <button
        type="button"
        class="anan-button--xl-large anan-button anan-button--primary anan-button--normal"
      >
        <span> {{ $t('topupSystem') }} </span>
      </button>

      <div class="transactions-table-wrap my-2">
        <div
          class="anan-table transaction-table anan-table--empty-data anan-table--with-append"
          style="position: relative"
        >
          <div class="hidden-columns" />
          <div class="anan-table__header-container">
            <div class="anan-table__main-header">
              <table
                cellspacing="0"
                cellpadding="0"
                border="0"
                class="anan-table__header"
                style="width: 100%"
              >
                <colgroup>
                  <col width="10%">
                  <col width="10%">
                  <col width="10%">
                  <col width="35%">
                  <col width="13%">
                  <col width="10%">
                  <col width="7%">
                  <col width="5%">
                </colgroup>
                <thead>
                  <tr>
                    <th
                      colspan="1"
                      rowspan="1"
                    >
                      <div class="anan-table__cell first-cell">
                        <span class="anan-table__cell-label">{{ $t('number') }}</span>
                      </div>
                    </th>

                    <th
                      colspan="1"
                      rowspan="1"
                    >
                      <div class="anan-table__cell">
                        <span
                          class="anan-table__cell-label"
                        >{{ $t('transactionDate') }}</span>
                      </div>
                    </th>

                    <th
                      colspan="1"
                      rowspan="1"
                    >
                      <div class="anan-table__cell">
                        <span class="anan-table__cell-label">{{ $t('approvalDate') }}</span>
                      </div>
                    </th>

                    <th
                      colspan="1"
                      rowspan="1"
                    >
                      <div class="anan-table__cell">
                        <span class="anan-table__cell-label">{{ $t('details') }}</span>
                      </div>
                    </th>

                    <th
                      colspan="1"
                      rowspan="1"
                    >
                      <div class="anan-table__cell last-cell">
                        <span
                          class="anan-table__cell-label"
                        >{{ $t('transactionEvidence') }}</span>
                      </div>
                    </th>

                    <th
                      colspan="1"
                      rowspan="1"
                    >
                      <div class="anan-table__cell last-cell">
                        <span class="anan-table__cell-label">{{ $t('quantity') }}</span>
                      </div>
                    </th>

                    <th
                      colspan="1"
                      rowspan="1"
                    >
                      <div class="anan-table__cell last-cell">
                        <span class="anan-table__cell-label">{{ $t('status') }}</span>
                      </div>
                    </th>

                    <th
                      colspan="1"
                      rowspan="1"
                    >
                      <div class="anan-table__cell last-cell">
                        <span class="anan-table__cell-label">{{ $t('manage') }}</span>
                      </div>
                    </th>
                  </tr>
                </thead>
              </table>
            </div>
          </div>
          <div class="anan-table__body-container">
            <div class="anan-table__main-body">
              <div class="anan-scrollbar">
                <div
                  class="anan-scrollbar__wrapper"
                  style="overflow: hidden"
                >
                  <div class="anan-scrollbar__bar horizontal">
                    <div
                      class="anan-scrollbar__thumb"
                      style="left: 0px; width: 0px"
                    />
                  </div>
                  <div
                    class="anan-scrollbar__content"
                    style="position: relative"
                  >
                    <table
                      cellspacing="0"
                      cellpadding="0"
                      border="0"
                      class="anan-table__body"
                      style="width: 100%"
                    >
                      <colgroup>
                        <col width="10%">
                        <col width="10%">
                        <col width="10%">
                        <col width="35%">
                        <col width="13%">
                        <col width="10%">
                        <col width="7%">
                        <col width="5%">
                      </colgroup>
                      <tbody>
                        <tr
                          v-for="(item, index) in 10"
                          :key="index"
                          class="anan-table__row valign-top"
                        >
                          <td class="is-first">
                            <div class="anan-table__cell first-cell">
                              <router-link :to="{name: '/'}">
                                RTX-12345678{{ index }}
                              </router-link>
                            </div>
                          </td>

                          <td>
                            <div class="anan-table__cell">
                              ss
                            </div>
                          </td>

                          <td>
                            <div class="anan-table__cell">
                              ss
                            </div>
                          </td>

                          <td>
                            <div class="anan-table__cell">
                              ss
                            </div>
                          </td>

                          <td>
                            <div class="anan-table__cell">
                              ss
                            </div>
                          </td>

                          <td>
                            <div class="anan-table__cell">
                              ss
                            </div>
                          </td>

                          <td>
                            <div class="anan-table__cell">
                              ss
                            </div>
                          </td>

                          <td class="is-last">
                            <div class="anan-table__cell">
                              <i class="fal fa-edit mr-1 cursor-pointer" />

                              <i class="fal fa-trash-alt cursor-pointer" />
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <!-- <div class="anan-table__empty">
                          <div class="anan-table__empty-content">

                            <img
                              src="@/assets/images/anan-img/svg/doc_empty.svg"
                              alt="empty"
                              class="empty-img"
                            >
                            <span
                              class="anan-table__empty-text"
                            >ไม่มีข้อมูล</span>
                          </div>
                        </div> -->
                  </div>
                </div>
              </div>
            </div>
            <div class="anan-scrollbar__bar vertical">
              <div
                class="anan-scrollbar__thumb"
                style="top: 0px; height: 0px"
              />
            </div>
          </div>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import { BCard } from 'bootstrap-vue'

export default {
  components: {
    BCard,
  },
  data() {
    return {
      search: '',
      isActive: 'all',
    }
  },
  methods: {
    getData(type) {
      this.isActive = type
    },
  },
}
</script>

    <style lang="scss" scoped></style>
